import { render, staticRenderFns } from "./car.vue?vue&type=template&id=50b4de99&scoped=true&"
import script from "./car.vue?vue&type=script&lang=js&"
export * from "./car.vue?vue&type=script&lang=js&"
import style0 from "./car.vue?vue&type=style&index=0&id=50b4de99&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50b4de99",
  null
  
)

export default component.exports