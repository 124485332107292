<template>
  <div>
    <div class="right">
      <div class="carList">
<!--        <div class="title">10个停车位</div>-->
        <div v-for="(item,index) in listData" :class="carId==item.id?'listBg list':'list'" @click="choose(item)">
          <img class="tp" :src="item.webImage" alt="">
          <div class="content">
            <div class="name">{{ item.name||'...' }}</div>
            <div class="count">
              <div class="total">总车位 <span style="font-weight: bold;font-size: 16px;margin-left: 5px">{{ item.totalNum||0 }}</span></div>
              <div class="surplus">剩余车位<span style="font-weight: bold;font-size: 16px;margin-left: 5px">{{ item.surplusNum||0 }}</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-img" id="map">

      </div>

    </div>


  </div>
</template>

<script>
import {parkList, imgURL} from '@/api'
import TMap from "../../utils/initMap";
export default {
  name: "sightseeing",
  data(){
    return {
      listData:[],
      carId:'',
      imgURL,
      list:[
        {
          type:1,
          list:[]
        },{
          type:2,
          list:[]
        }
      ],
      totalCount: 0,
      current_page: 1,
      per_page: 4,
      istan:false,

      // 地图
      center: {lng: 0, lat: 0},
      zoom: 3,
      mapIcon:'https://ppt.chnlib.com/FileUpload/2018-11/7-11Zhang_Gao_1ing_Jia-110636_103.png',
      geometries:[],
      masker:[],
      tMap:[]
    }
  },
  mounted() {
    // this.initMap()
    this.getList()
  },
  methods:{
    // 地图
    initMap(latitude,longitude){
      //定义地图中心点坐标
      TMap.init().then((TMap) => {
        this.TXMap = TMap;
        this.tMap = new TMap.Map("map", {
          center: new TMap.LatLng(latitude, longitude), //设置地图中心点坐标
          zoom:16, //设置地图缩放级别
          viewMode: "2D",
        });
        this.masker = new TMap.MultiMarker({
          map: this.tMap,
          styles: {  // 点标记样式
            "myStyle": new TMap.MarkerStyle({
              "width": 54,
              "height": 64,
              "src": '/img/carto.png',
              "anchor": {x: 10, y: 10},
              "opacity": 1
            })
          },
          geometries: [{
            "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            "styleId": 'myStyle',  //指定样式id
            "position": new TMap.LatLng(latitude, longitude),  //点标记坐标位置
            "properties": {//自定义属性
              "title": "停车场"
            }
          }]
        })
      });

    },
    getList(){
      let that =this
      parkList({}).then(res=>{
        // console.log(res)
        if(res.code==200){
          that.listData=res.rows
          that.carId=res.rows[0].id
          // let obj= {
          //   "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          //   "styleId": 'myStyle',  //指定样式id
          //   "position": new TMap.LatLng(res.rows[0].latitude, res.rows[0].longitude),  //点标记坐标位置
          //   "properties": {//自定义属性
          //     "title": "停车场"
          //   }
          // }
          // this.geometries.push(obj)
          // this.initMap()

          that.initMap(res.rows[0].latitude,res.rows[0].longitude)
        }
      })
    },
    choose(item){
      this.carId=item.id
      // this.initMap(item.latitude,item.longitude)
      TMap.init().then((TMap) => {
        this.tMap.setCenter(new TMap.LatLng(item.latitude,item.longitude))
        this.masker.updateGeometries([
          {
            "styleId":"myStyle",
            "id": "1",
            "position": new TMap.LatLng(item.latitude,item.longitude),
          }
        ])
      })


    }
  }
}
</script>

<style scoped lang="less">
/deep/#map{
  div:first-child{
    div:first-child+div{
      display: none!important;
    }
  }
}
.tan{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(27, 25, 25, 0.7);
  z-index:99;
  //.tan1{
  .tan-1{
    width: 350px;
    height: 100%;
    //background: red;
    margin: 0 auto;
    padding-top: 200px;
    box-sizing: border-box;
    .tan-1-1{
      font-size: 34px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      text-align: center;
    }
    .tan-1-2{
      width: 350px;
      height: 350px;
      margin: 51px 0 68px;
      border-radius: 20px;
      overflow: hidden;
    }
    .tan-1-3{
      width: 61px;
      height: 61px;
      margin: 0 auto;
      cursor: pointer;
      .tan-1-3-icon{
        font-size: 80px;
        color: #FFFFFF;
        //font-weight: 700;
      }
    }
  }
  //}
}
.right{
  //padding-bottom: 100px;
  padding: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .right-img{
    width: 73%;
    border-radius: 30px;
    height: 600px;
    background: #fff;
    overflow: hidden;
  }
  .carList::-webkit-scrollbar { width: 0 !important }
  .carList { -ms-overflow-style: none; }
  .carList { overflow: -moz-scrollbars-none; }
  .carList{
    width: 25%;
    border-radius: 30px;
    height: 600px;
    background: #fff;
    //overflow: hidden;
    overflow: scroll;
    .title{
      font-size: 20px;
      font-family: STSongti-SC-Black, STSongti-SC;
      font-weight: 900;
      color: #333333;
      line-height: 80px;
      text-align: center;
    }
    .listBg{
      background: #F2F7FA;
    }
    .list{
      width: 100%;
      //height: 200px;
      display: flex;
      flex-wrap: nowrap;
      padding: 25px;
      .tp{
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 5px;
      }
      .content{
        width: calc(100% - 70px);
        margin-left: 20px;
        .name{
          font-size: 20px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 500;
          color: #333333;
          line-height: 50px;
        }
        .count{
          display: flex;
          justify-content: space-between;
          .total{
            //width: ;
            background: #F2F2F2;
            padding: 10px 20px;
            box-sizing: border-box;
            border-radius: 20px;
            color: #7B7B7B;
          }
          .surplus{
            background: #E7F2F4;
            padding: 10px 20px;
            box-sizing: border-box;
            border-radius: 20px;
            color: #057C87;
          }
        }
      }
    }
  }
  .right-1{
    width: 1230px;
    margin: 56px auto 190px;
    .right-1-1{
      margin-bottom: 60px;
      .right-1-1-1{
        display: flex;
        margin-bottom: 30px;
        margin-left: 15px;
        .right-1-1-1-1{
          font-size: 34px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 700;
          color: #333333;
        }
        .right-1-1-1-2{
          font-size: 14px;
          font-family: STSongti-SC-Bold, STSongti-SC;
          font-weight: bold;
          color: #333333;
          margin-left: 20px;
          margin-top: 20px;
        }
      }
      .right-1-1-2{
        .right-1-1-2-1{
          width: 380px;
          min-height: 210px;
          display: inline-block;
          background: #FFFFFF;
          border-radius: 20px;
          margin: 0 15px;
          padding: 30px 0 24px 24px;
          .right-1-1-2-1-1{
            font-size: 20px;
            font-family: STSongti-SC-Black, STSongti-SC;
            font-weight: 900;
            color: #333333;
          }
          .right-1-1-2-1-2{
            font-size: 14px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            margin: 10px 0;
            /* 文本不换行 */
            white-space: nowrap;
            /* 超出范围隐藏 */
            overflow: hidden;
            /* 文字超出用省略号 */
            text-overflow: ellipsis;
          }
          .right-1-1-2-1-3{
            margin: 10px 0;
          }
          .right-1-1-2-1-4{
            //padding-right: 20px;
            display: flex;
            justify-content: flex-end;
            .right-1-1-2-1-4-1{
              font-size: 34px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #007B8A;
            }
            .right-1-1-2-1-4-2{
              width: 80px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              background: #007B8A;
              border-radius: 5px 0px 0px 5px;
              margin-left: 20px;
              font-size: 14px;
              font-family: STSongti-SC-Regular, STSongti-SC;
              font-weight: 400;
              color: #FFFFFF;
              cursor: pointer;
            }
          }
        }
      }
    }
  }





}
/deep/.paging-device {
  margin: 70px 0 70px -50px;
  display: flex;
  justify-content: center;
}
</style>
